'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';

export default class ScrollHeader {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'ScrollHeader';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;

        this.commonMethods.isDomReady().then(() => {
            this.init();
        });
    }

    /**
     * Initialize
     *
     * @returns {this}
     */
    init () {
        this.mainHeader = document.querySelector('.l-page-header');
        this.scrollHeaderContainer = document.querySelector('[data-scrollheader]');
        if (!this.mainHeader || !this.scrollHeaderContainer) {
            return;
        }

        // We only need this effect for screens wider than 800px
        if (window.matchMedia('(min-width: 1020px)').matches) {
            this.observer = new IntersectionObserver(this.handleIntersection.bind(this));

            // Observe the target element
            this.observer.observe(this.mainHeader);
        } else {
            // For smaller screens, we need to use the scrollHeader as our main header.
            // Add the required extra spacing for content below the header.
            this.pageContent = document.querySelector('.l-page-content');
            this.pageContent.classList.add('l-page-content--extra-spacing-top');
        }
    }

    handleIntersection (entries) {
        const entry = entries[0];
        if (entry.isIntersecting) {
            this.scrollHeaderContainer.classList.remove('is-visible');
        } else {
            // If header is no longer intersecting, bring in the scrolling header
            this.scrollHeaderContainer.classList.add('is-visible');
        }
    }
}
