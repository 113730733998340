'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';

export default class TabNav {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'Tabnav';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;

        this.commonMethods.isDomReady().then(() => {
            this.init();
        });
    }

    /**
     * Initialize
     *
     * @returns {this}
     */
    init () {
        const tabnavigations = document.querySelectorAll('[data-tabnav]');

        tabnavigations.forEach(tabnav => {
            this.handleTabNav(tabnav);
        });
    }

    /**
     * @param {HTMLDivElement} tabnav
     */
    handleTabNav (tabnav) {
        this.logger.log('Handling a tabnav', tabnav);
        const tablist = tabnav.querySelector('.c-tabnav');

        if (!tablist) {
            this.logger.log('Couldn’t find element with "c-tabnav" class within tabnav. Stopping here', tabnav);
            return;
        }

        const tabs = tablist.querySelectorAll('a');
        const panels = tabnav.querySelectorAll('[id^="section"]');

        // Add the tablist role to the first <ul> in the tabbed container
        tablist.setAttribute('role', 'tablist');

        // Add semantics are remove user focusability for each tab
        Array.prototype.forEach.call(tabs, (tab, i) => {
            tab.setAttribute('role', 'tab');
            tab.setAttribute('id', 'tab' + (i + 1));
            tab.setAttribute('tabindex', '-1');
            tab.parentNode.setAttribute('role', 'presentation');

            // Handle clicking of tabs for mouse users
            tab.addEventListener('click', e => {
                e.preventDefault();
                const currentTab = tablist.querySelector('[aria-selected]');
                if (e.currentTarget !== currentTab) {
                    this.switchTab(currentTab, e.currentTarget, tabs, panels);
                }
            });

            // Handle keydown events for keyboard users
            tab.addEventListener('keydown', e => {
                // Get the index of the current tab in the tabs node list
                const index = Array.prototype.indexOf.call(tabs, e.currentTarget);
                // Work out which key the user is pressing and
                // Calculate the new tab's index where appropriate
                const dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
                if (dir !== null) {
                    e.preventDefault();
                    // If the down key is pressed, move focus to the open panel,
                    // otherwise switch to the adjacent tab
                    if (dir === 'down') {
                        panels[i].focus();
                    } else if (tabs[dir]) {
                        this.switchTab(e.currentTarget, tabs[dir], tabs, panels);
                    } else {
                        // Do nothing (undefined)
                    }
                }
            });
        });

        // Add tab panel semantics and hide them all
        Array.prototype.forEach.call(panels, (panel, i) => {
            panel.setAttribute('role', 'tabpanel');
            panel.setAttribute('tabindex', '-1');
            const id = panel.getAttribute('id');
            if (id) {
                panel.setAttribute('aria-labelledby', tabs[i].id);
                panel.hidden = true;
            }
        });

        // Initially activate the first tab and reveal the first tab panel
        if (tabs[0]) {
            tabs[0].removeAttribute('tabindex');
            tabs[0].setAttribute('aria-selected', 'true');
        }

        if (panels[0]) {
            panels[0].hidden = false;
        }

        // check if the URL contains a hash for a tab, if yes: select it
        if (window && window.location.hash) {
            const tab = Array.from(tabs).find(tab => {
                return tab.getAttribute('href') === window.location.hash;
            });

            if (tab) {
                tab.click();
                tab.blur();
            }
        }
    }

    switchTab (oldTab, newTab, tabs, panels) {
        newTab.focus();
        // Make the active tab focusable by the user (Tab key)
        newTab.removeAttribute('tabindex');
        // Set the selected state
        newTab.setAttribute('aria-selected', 'true');
        oldTab.removeAttribute('aria-selected');
        oldTab.setAttribute('tabindex', '-1');
        // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide
        const index = Array.prototype.indexOf.call(tabs, newTab);
        const oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
    }
}
