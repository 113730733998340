'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';

export default class BookingHandler {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'BookingHandler';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.api_url = '/api/bookings/';

        this.commonMethods.isDomReady().then(() => {
            this.init();
        });
    }

    /**
     * Initialize
     *
     * @returns {this}
     */
    init () {
        document
            .querySelectorAll('[data-booking-delete]')
            .forEach((button) => {
                button.addEventListener('click', () => {
                    this.deleteBooking(button);
                });
            });
    }

    /**
     * Renders messages from api call
     * @param {string} messageBlockSelector
     * @param {string} message
     * @param {HTMLElement} activeDialog
     */
    renderMessage (messageBlockSelector, message, activeDialog) {
        const messageBlock = activeDialog.querySelector(messageBlockSelector);

        if (messageBlock == null) {
            this.logger.error('Cannot render message: failed to find message block on activeDialog', {
                activeDialog,
                messageBlockSelector,
            });

            return;
        }

        messageBlock.innerHTML = message;
        messageBlock.classList.remove('u-hide');
    }

    /**
     * Disable buttons after being clicked
     * @param {HTMLElement} activeDialog
     */
    disableButtons (activeDialog) {
        activeDialog
            .querySelectorAll('.c-button')
            .forEach(button => button.classList.add('is-disabled'));
    }

    /**
     * Hides content elements within a selected dialog window
     * @param {HTMLElement} activeDialog
     */
    hideDialogElements (activeDialog) {
        const heading = activeDialog.querySelector('.o-headline');

        if (heading != null) {
            heading.classList.add('u-hide');
        }

        const buttons = activeDialog.querySelector('[data-button-group]');

        if (buttons != null) {
            buttons.classList.add('u-hide');
        }
    }

    /**
     * Show loader
     * @param {HTMLElement} activeDialog
     */
    showLoader (activeDialog) {
        const loader = activeDialog.querySelector('.o-loader');

        if (loader != null) {
            loader.classList.remove('u-hide');
        }
    }

    /**
     * Deletes a booking using the API
     * Expects the button with bookingId
     * @param {HTMLElement} booking
     */
    deleteBooking (booking) {
        const bookingId = booking.dataset.bookingDelete;

        if (!bookingId) {
            this.logger.log('Cannot delete booking by ID: no booking ID via [data-booking-delete] attribute set');
            return;
        }

        this.logger.log('Deleting Booking with id', bookingId);

        const activeDialog = document.querySelector('.c-dialog:not([aria-hidden="true"])');

        if (activeDialog === null) {
            // The dialog component always adds this attribute to show the proper dialog, but just in case, something
            // breaks there, we just kill this script.
            this.logger.error('Dialog has aria-hidden="true" value and cannot be used.');
            return;
        }

        this.disableButtons(activeDialog);

        fetch(this.api_url + bookingId + '/', {
            method: 'DELETE',
        })
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    throw new Error(response.data.message);
                }
                this.renderMessage('[data-booking-success]', response.data.message, activeDialog);
            })
            .catch(error => {
                this.renderMessage('[data-booking-error]', error, activeDialog);
                this.logger.error(error);
            })
            .finally(() => {
                this.hideDialogElements(activeDialog);
                this.showLoader(activeDialog);

                // this timeout allows the user to read the success/error message
                setTimeout(() => location.reload(), 1000);
            });
    }
}
